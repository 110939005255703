@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");


body {
  display: block;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: linear-gradient(270deg, rgba(205,240,255,1) 0%, rgba(113,188,255,1) 100%);
  height: 7.5vh;
  top: 0;
  z-index: 99;
}

.main-nav {
  position: -webkit-sticky; /* safari */
  position: sticky;
  width: 100vw;
  max-width: 100%;
}

.main-nav li {
  float: left;
  height: 100%;
}

.vertical-nav {
  position: -webkit-sticky; /* safari */
  position: sticky;
  height: 8vh;
}

.vertical-nav-active::after {
  position: -webkit-sticky; /* safari */
  position: sticky;
  display: block;
}


.vertical-nav li {
  position: relative;
  z-index: 100;
  width: 100vw;
  max-width: 100%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 2vh;
}

.collapsing-div {
  background: linear-gradient(270deg, rgba(205,240,255,1) 0%, rgba(113,188,255,1) 100%);
  transition: height 500ms ease-in-out;
  position: relative;
  top: -0.1vh;
  height: 0vh;
}

.collapsing-div li button {
  opacity: 0;
  font-family: "Didact Gothic", sans-serif;
}

.bars {
  height: 4vh;
}

.main-nav li button {
  display: block;
  background-color: transparent;
  text-align: center;
  padding: 2vmin 16px;
  text-decoration: none;
  font-size: 2.5vh;
  color: white;
  border: none;
  position: relative;
  height: 100%;
  font-family: "Didact Gothic", sans-serif;
}

.vertical-nav li button {
  color: white;
  font-size: 2.5vh;
  background-color: transparent;
  border: none;
}

.vertical-nav li:hover button {
  color: rgb(18, 92, 157);
  cursor: pointer;
}


.main-nav button:hover {
  color: rgb(18, 92, 157);
  cursor: pointer;
}

.collapsing-div li:hover{
  color: rgb(18, 92, 157);
  cursor: pointer;
}

.nav-bars {
  position: absolute;
  height: 5vw;
  left: 1vw;
  cursor: pointer;
  top: 50%;
  transform: translate(0%, -50%);
}

.nav-bars:hover{
  color: rgb(18, 92, 157);
  cursor: pointer;
}


@media screen and (max-width: 500px) {
  .main-nav li button {
    display: none;
  }
  .navbars {
    display: inline;
  }
}

.collapsing-div li .active {
  opacity: 0;
  animation: fade-in 1s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} 


.hide {
  display: none;
}