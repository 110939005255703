@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

#socials {
  position: relative;
  margin: 0;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.socials-header {
  font-size: 5vw;
  text-align: center;
  font-family: "Indie Flower", cursive;
}

.socials-subheader {
  font-size: 2.5vw;
  text-align: center;
  font-family: "Indie Flower", cursive;
  margin: 0;
}

.socials-container {
  position: relative;
  width: 50vw;
  left: 25vw;
  display: flex;
}


.contact-icon {
  font-size: 3vw;
}

.contact-info {
  margin: 0;
  position: relative;
  left: 2vw;
  font-size: 2vw;
}

.link {
  cursor: pointer;
}

.social-imgs-container {
  width: 4vw;
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
}

.social-text-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
}

@media screen and (max-width: 750px) {
  .socials-header {
    font-size: 7.5vw;
  }

  .socials-subheader {
    font-size: 4vw;
  }

}