@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

#projects {
  margin: 0;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

.projects-header {
  font-size: 5vw;
  text-align: center;
  font-family: "Indie Flower", cursive;
}

.projects-subheader {
  margin: 0;
  font-size: 2.5vw;
  text-align: center;
  font-family: "Indie Flower", cursive;
}

.projects-container {
  position: relative;
  width: 90vw;
  left: 5vw;
  top: 0vh;
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  overflow: hidden;
}

.project {
  position: relative;
  width: 28.4vw;
  height: 35vw;
  left: 0.5vw;
  top: 0.5vw;
  border-radius: 1vw;
  transition: transform 0.5s ease-in-out;
  margin-bottom: 1vh;
  z-index: 1;
}

.project-inner {
  position: relative;
  width: 27.6vw;
  height: 34.2vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.4vw solid black;
  background-color: white;
  border-radius: 0.9vw;
  z-index: 2;
}

.project-img-container {
  position: relative;
  width: 80%;
  height: 35%;
  left: 10%;
  top: 5%;
  border: 2px solid black;
  border-radius: 0.5vw;
}

.project-desc {
  margin: 2vw;
  font-size: 1.5vw;
}

.project h3 {
  margin: 0;
  text-align: center;
  font-family: "Indie Flower", cursive;
  font-size: 2vw;
}

@media screen and (max-width: 750px) {
  .project {
    position: relative;
    width: 42.6vw;
    height: 52.5vw;
    left: 1.5vw;
    top: 0.5vw;
  }

  .project-inner {
    position: relative;
    width: 41.4vw;
    height: 51.3vw;
  }

  .project-desc {
    margin: 3.5vw;
    font-size: 2.25vw;
  }

  .project h3 {
    font-size: 3vw;
  }

  .projects-header {
    font-size: 7.5vw;
  }
  
  .projects-subheader {
    margin: 0;
    font-size: 4vw;
  }
}

@media screen and (max-width: 425px) {
  .project {
    position: relative;
    width: 85.2vw;
    height: 105vw;
    left: 2.4vw;
    top: 0.5vw;
  }

  .project-inner {
    position: relative;
    width: 82.8vw;
    height: 102.6vw;
  }

  .project-desc {
    margin: 7vw;
    font-size: 4.5vw;
  }

  .project h3 {
    font-size: 6vw;
  }
}

.project:hover {
  background: linear-gradient(
    270deg,
    rgba(205, 240, 255, 1) 0%,
    rgba(113, 188, 255, 1) 100%
  );
  cursor: pointer;
}

.project:hover .project-inner {
  border: none;
}

.scrolled.slide-right-projects:nth-child(1) {
  animation: slide-in-right 1s ease-in-out both;
}
.scrolled.slide-right-projects:nth-child(2) {
  animation: slide-in-right 1s ease-in-out both;
  animation-delay: 0.33s;
}
.scrolled.slide-right-projects:nth-child(3) {
  animation: slide-in-right 1s ease-in-out both;
  animation-delay: 0.67s;
}

.scrolled.slide-right-projects:nth-child(4) {
  animation: slide-in-right 1s ease-in-out both;
}
.scrolled.slide-right-projects:nth-child(5) {
  animation: slide-in-right 1s ease-in-out both;
  animation-delay: 0.33s;
}

.scrolled.slide-right-projects:nth-child(6) {
  animation: slide-in-right 1s ease-in-out both;
  animation-delay: 0.67s;
}

@media screen and (max-width: 775px) {
  .scrolled.slide-right-projects:nth-child(3) {
    animation: slide-in-right 1s ease-in-out both;
  }

  .scrolled.slide-right-projects:nth-child(4) {
    animation: slide-in-right 1s ease-in-out both;
    animation-delay: 0.33s;
  }
  .scrolled.slide-right-projects:nth-child(5) {
    animation: slide-in-right 1s ease-in-out both;
  }

  .scrolled.slide-right-projects:nth-child(6) {
    animation: slide-in-right 1s ease-in-out both;
    animation-delay: 0.33s;
  }
}

@media screen and (max-width: 425px) {
  .scrolled.slide-right-projects:nth-child(2) {
    animation: slide-in-right 1s ease-in-out both;
  }

  .scrolled.slide-right-projects:nth-child(3) {
    animation: slide-in-right 1s ease-in-out both;
  }

  .scrolled.slide-right-projects:nth-child(4) {
    animation: slide-in-right 1s ease-in-out both;
  }
  .scrolled.slide-right-projects:nth-child(5) {
    animation: slide-in-right 1s ease-in-out both;
  }

  .scrolled.slide-right-projects:nth-child(6) {
    animation: slide-in-right 1s ease-in-out both;
  }
}

.project-info-container {
  position: fixed;
  width: 96%;
  left: 1.7%;
  top: 2.5%;
  height: 95%;
  /* outline: 0.3vw solid black; */
  z-index: 100;
  background: linear-gradient(
    270deg,
    rgba(227, 247, 255, 1) 0%,
    rgba(166, 213, 255, 1) 100%
  );
  border: 0.3vw solid black;
  border-radius: 1vh;
  animation: fade-in 0.5s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.close-info-menu {
  position: absolute;
  right: 0.2vw;
  top: 0.2vw;
  font-size: 3vmax;
  transition: transform 0.5s ease-in-out;
}

.close-info-menu:hover {
  cursor: pointer;
  transform: rotate(90deg);
}

.content-container {
  position: relative;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  /* border: 2px solid black; */
  overflow: auto;
}

.content-header {
  margin: 0;
  text-align: center;
  font-size: 4vmax;
  font-family: "Indie Flower", cursive;
}

.repo-demo-container {
  width: 80vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  justify-content: center;
  gap: 1vw;
}

.repo-link{
  position: relative;
  text-decoration: underline;
  font-size: 2vmax;
}

.demo-link{
  position: relative;
  text-decoration: underline;
  font-size: 2vmax;
}


.repo-link:hover, .demo-link:hover {
  cursor: pointer;
  color: rgb(31, 150, 255);
}

.content-desc {
  font-size: 2vmax;
}
