@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@600&display=swap");

h1 {
  margin: 0;
}

#home {
  margin: 0;
  position: relative;
  top: -1vh;
  height: 100vh;
  max-height: 100%;
  max-height: 100vh;
  width: 100vw;
  max-width: 100%;
  background: linear-gradient(
    270deg,
    rgba(205, 240, 255, 1) 0%,
    rgba(113, 188, 255, 1) 100%
  );
  overflow: hidden;
}

.home-container {
  position: absolute;
  width: 95vw;
  height: 60vh;
  left: 2.5vw;
  top: 15vh;
  /* overflow: hidden; */
}

.welcome-text-container {
  position: absolute;
  height: 60vmin;
  width: 50vw;
  top: 50%;
  transform: translate(0%, -50%);
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.welcome-text {
  width: 100%;
  max-width: 100%;
  color: white;
  margin: auto;
  font-size: 6.3vmax;
  z-index: 10;
  font-family: "Caveat", cursive;
  vertical-align: center;
}

.home-picture {
  width: 32vmax;
  height: 40vmax;
  position: absolute;
  right: 0%;
  border-radius: 72% 28% 25% 75% / 52% 54% 46% 48%;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 1;
}

.project-link-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 20vh;
}

.project-link-container h3 {
  margin: 0;
  color: white;
  font-size: 2vmax;
  cursor: pointer;
}

span {
  opacity: 0;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  font-size: 5vmin;
  font-family: "Caveat", cursive;
}

.arrow-down-icon {
  font-size: 3.5vmin;
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  animation-delay: 4.7s;
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  animation-delay: 4.8s;
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  animation-delay: 4.9s;
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(10px);
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}

@media screen and (max-width: 400px) {
  .home-container {
    width: 95vw;
    height: 60vh;
    left: 2.5vw;
  }
  .home-picture {
    width: 30vmax;
    height: 37.5vmax;
    position: absolute;
    right: 0vw;
  }
}

@media screen and (max-width: 750px) {
  .project-link-container {
    bottom: 25vh;
  }
}

@media screen and (max-width: 400px) {
  .project-link-container {
    bottom: 30vh;
  }
}



