@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");

#bio {
  margin: 0;
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.bio-header {
  font-size: 5vw;
  text-align: center;
  font-family: "Indie Flower", cursive;
}

.bio-container {
  position: relative;
  width: 85vw;
  height: 40vw;
  left: 7.5vw;
  top: 0vh;
}

.bio-picture {
  position: absolute;
  width: 30vw;
  height: 22.5vw;
  top: 3.75vw;
  /* left: 2.5vw; */
  --s: 2vw;
  border: calc(2 * var(--s)) solid #0000;
  outline: 0.35vw solid #000;
  outline-offset: calc(-1 * var(--s));
  background: conic-gradient(from 90deg at 1vw 1vw, #0000 100%, #000 0);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.bio-desc-container {
  position: absolute;
  height: 30vw;
  width: 30vw;
  border: 2px solid black;
  --s: 2vw;
  right: 2.5vw;
  border: calc(2 * var(--s)) solid #0000;
  outline: 0.35vw solid #000;
  outline-offset: calc(-1 * var(--s));
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: auto;
}

.bio-desc {
  font-family: "Didact Gothic", sans-serif;
  font-size: 1.5vw;
}

@media screen and (max-width: 750px) {
  .bio-container {
    width: 85vw;
    height: 135vw;
    left: 7.5vw;
    top: 0vh;
  }

  .bio-picture {
    position: relative;
    width: 50vw;
    height: 37.5vw;
    left: 11.5vw;
    --s: 3vw;
    border: calc(2 * var(--s)) solid #0000;
    outline: 0.35vw solid #000;
    outline-offset: calc(-1 * var(--s));
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: auto;
  }

  .bio-desc-container {
    position: relative;
    height: 50vw;
    width: 50vw;
    left: 11.5vw;
    overflow: auto;
    top: 6vh;
    --s: 3vw;
    right: 2.5vw;
    border: calc(2 * var(--s)) solid #0000;
    outline: 0.35vw solid #000;
    outline-offset: calc(-1 * var(--s));
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: auto;
  }

  .bio-desc {
    font-family: "Didact Gothic", sans-serif;
    font-size: 3vw;
  }

  .bio-header {
    font-size: 7.5vw;
  }
}

.scrolled {
  opacity: 0;
}

.scroll::after{
  transition: opacity 500ms;
  opacity: 1;
}


.scrolled.slide-right:nth-child(1) {
  animation: slide-in-right 1s ease-in-out both;
}

.scrolled.slide-right:nth-child(2) {
  animation: slide-in-right 1s ease-in-out both;
  animation-delay: 0.75s;
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(10vw);
    transform: translateX(10vw);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
